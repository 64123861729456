  <template>
      <a-card :title="$t('points.properties')">
        <a-form>
          <a-row>
            <a-col :span="6" style="margin-top: 8px">{{ $t('cameraSettings.recordModeLabel') }}</a-col>
            <a-col :span="6" style="margin-top: 8px">
              <a-select :options="availRecordModes" :value=" lastValues.viewpointRecordMode" @change="(val) => handlePropChange('viewpointRecordMode', val)"></a-select>
            </a-col>
          </a-row>
          <div v-if="isThermalModeSelected">
            <a-row>
              <a-col :span="6" style="margin-top: 8px">{{ $t('cameraSettings.thermalModeLabel') }}</a-col>
              <a-col :span="6" style="margin-top: 8px">
                <a-select
                  :options="availThermalModes" :value="this.form[0].viewpointThermalMode !== null ? this.form[0].viewpointThermalMode : lastValues.viewpointThermalMode" @change="(val) => handlePropChange('viewpointThermalMode', val)">
                </a-select>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="16" style="margin-top: 8px">
                <a-checkbox :checked="this.form[0].viewpointThermalRange === 'high'"  @change="(e) => handlePropChange('viewpointThermalRange', e.target.checked ? 'high' : 'low')"> {{ $t('cameraSettings.thermalRangeHighLabel') }}</a-checkbox>
              </a-col>
            </a-row>
          </div>
          <a-row v-else-if="isPhotoModeSelected">
            <a-col :span="6" style="margin-top: 8px"><p>{{ $t('cameraSettings.photoIntervalLabel') }}</p></a-col>
            <a-col :span="3">
              <a-input-number id="inputNumber" :included="false" :min="2.0" :step="0.1"
                  :value="this.form[0].viewpointPhotoModeInterval !== null ? this.form[0].viewpointPhotoModeInterval : lastValues.viewpointPhotoModeInterval"
                  @change="(val) => handlePropChange('viewpointPhotoModeInterval', val)"/>
            </a-col>
            <a-col :span="5" :offset="1" style="margin-top: 8px;">
              <span class="info-content"> {{$tc('units.second', 2)}}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6" style="margin-top: 2rem">
              <a-checkbox v-if="isFPManager" :checked="form[0].viewpointMode === 'auto'" @change="(e) => (changeViewType(e.target.checked))">Auto</a-checkbox>
              <div v-else>Auto</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6" style="margin-top: 8px">
              <a-checkbox v-if="isFPManager" :checked="panForbiddenMinChecked" @change="(e) => onForbiddenMinPanChange(e.target.checked)">{{ $t("points.viewpoints.labelForbiddenMin") }}</a-checkbox>
              <div v-else>{{ $t("points.viewpoints.labelForbiddenMin") }}</div>
            </a-col>
            <a-col :span="2">
             <a-input-number id="inputNumber" :included="false" :min="-180"
                  :max="180" :disabled="!panForbiddenMinChecked" :defaultValue="0" :marks="forbiddenAnglesSliderMarks"
                  :value="this.form[0].viewpointMinForbiddenPan !== null ? this.form[0].viewpointMinForbiddenPan : 0" @change="onPropChangeMin"/>
            </a-col>
            <a-col :offset="9" style="span: 10">
              <a-form-item v-if="isFPManager" :validate-status="getValidateStatus('viewpointMinForbiddenPan')">
                <a-slider :included="false" :min="-180" :max="180" :disabled="!panForbiddenMinChecked" :defaultValue="0" :marks="forbiddenAnglesSliderMarks"
                  :value="this.form[0].viewpointMinForbiddenPan !== null ? this.form[0].viewpointMinForbiddenPan : 0" @change="onPropChangeMin"/>
              </a-form-item>
              <div v-else style="margin-top: 8px;">{{form.viewpointMinForbiddenPan ? form.viewpointMinForbiddenPan : $t("common.noValue")}}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6" style="margin-top: 8px">
              <a-checkbox v-if="isFPManager" :checked="panForbiddenMaxChecked" @change="(e) => onForbiddenMaxPanChange(e.target.checked)"> {{ $t("points.viewpoints.labelForbiddenMax") }}</a-checkbox>
              <div v-else>{{ $t("points.viewpoints.labelForbiddenMax") }}</div>
            </a-col>
             <a-col :span="2">
             <a-input-number id="inputNumber"
                :included="false" :min="-180" :max="180" :disabled="!panForbiddenMaxChecked" :defaultValue="0" :marks="forbiddenAnglesSliderMarks"
                :value="this.form[0].viewpointMaxForbiddenPan !== null ? this.form[0].viewpointMaxForbiddenPan : 0" @change="onPropChangeMax"/>
            </a-col>
            <a-col :offset="9">
              <a-form-item v-if="isFPManager" :validate-status="getValidateStatus('viewpointMaxForbiddenPan')">
                <a-slider :included="false" :min="-180" :max="180" :disabled="!panForbiddenMaxChecked" :defaultValue="0"
                  :marks="forbiddenAnglesSliderMarks" :value="this.form[0].viewpointMaxForbiddenPan !== null ? this.form[0].viewpointMaxForbiddenPan : 0" @change="onPropChangeMax"/>
              </a-form-item>
              <div v-else style="margin-top: 8px;">
                {{ form.viewpointMaxForbiddenPan ? form.viewpointMaxForbiddenPan : $t("common.noValue")}}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6" style="margin-top: 8px">
              <a-checkbox v-if="isFPManager" :checked="tiltChecked" @change="(e) => onTiltChange(e.target.checked)"> Tilt </a-checkbox>
              <div v-else>Tilt</div>
            </a-col>
            <a-col span="16" :offset="2">
              <a-form-item v-if="isFPManager" :validate-status="getValidateStatus('viewpointMinTilt')">
                <a-slider range :min="-90" :max="90" :disabled="!tiltChecked" :defaultValue="[-30, 30]" :marks="tiltSliderMarks" :value="tiltRange" @change="onTiltChangeValue"/>
              </a-form-item>
              <div v-else style="margin-top: 8px;">
                {{ form.viewpointMinTilt ? form.viewpointMinTilt : $t("common.noValue") }}
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6" style="margin-top: 8px">
              <a-checkbox v-if="isFPManager" :checked="durationChecked" @change="(e) => onDurationChange(e.target.checked)"> {{ $t("points.viewpoints.labelDuration") }}</a-checkbox>
              <div v-else>{{ $t("points.viewpoints.labelDuration") }}</div>
            </a-col>
            <a-col :span="6" :offset="2">
              <a-form-item v-if="isFPManager" :validate-status="getValidateStatus('viewpointMoveTimeout')">
                <a-input-number :disabled="!durationChecked" :value="form[0].viewpointMoveTimeout" @change="onChangeDurationValue" @keyup.enter="() => saveProperties()"></a-input-number>
              </a-form-item>
              <div v-else style="margin-top: 8px;">{{form[0].viewpointMoveTimeout ? form[0].viewpointMoveTimeout : $t("common.noValue")}}</div>
            </a-col>
            <a-col :span="5" :offset="1" style="margin-top: 8px;"><span class="info-content">{{ $tc("units.second", 2) }}</span></a-col>
          </a-row>
        </a-form>
        <a-row v-if="!this.form[0].isPausable">
          <VueSlideBar :min="1" :max="5" v-model="slider.value" :data="slider.data" :range="slider.range"
            :labelStyles="{ color: '#1066FD', backgroundColor: '#4a4a4a' }" :processStyle="{ backgroundColor: '#d8d8d8' }"
            @callbackRange="callbackRange" @input="changeRadius">
          </VueSlideBar>
        </a-row>
        <a-row v-if="isFPManager">
          <a-col :xs="{ span: 10, offset: 2 }" :sm="{ span: 11, offset: 0 }" :md="{ span: 9, offset: 2 }" :xl="{ span: 5, offset: 8 }" :xxl="{ span: 4, offset: 8 }">
            <a-button @click="() => cancel()" class="btn btn-danger" type="danger">{{ $t("form.cancel") }}</a-button>
          </a-col>
          <a-col :xs="14" :sm="6" :md="8" :xl="8" :xxl="8">
            <a-button @click="() => saveProperties()" class="btn" type="primary">{{ $t("form.save") }}</a-button>
          </a-col>
        </a-row>
      </a-card>
</template>
<script>
import Vue from "vue"
import {Row,Input, Button, Form, Checkbox, Card, InputNumber, Slider} from "ant-design-vue"
import { isPanValid, isTiltValid } from "./FormValidation"
import VueSlideBar from "vue-slide-bar"
import { isFPManager } from "../../utils/userAuth"

Vue.use(Row)
Vue.use(Input)
Vue.use(Button)
Vue.use(Form)
Vue.use(Checkbox)
Vue.use(InputNumber)
Vue.use(Card)
Vue.use(Slider)

const VALID_RECORD_MODES = ['videohd', 'video4k', 'photolapse', 'thermal', 'disabled']
const VALID_THERMAL_MODES = ['rainbow', 'fusion', 'black_hot', 'white_hot']

export default {
  name: "view-props-form",
  components: {
    VueSlideBar,
  },
  props: {
    view: {
      type: [Object, Array],
    },
    eventBus: Object,
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    tiltRange() {
        if (this.form[0].viewpointMinTilt === null && this.form[0].viewpointMaxTilt === null) {
          return [-30, 0]
        }
        return [this.form[0].viewpointMinTilt, this.form[0].viewpointMaxTilt]
    },
    isThermalModeSelected() {
      return this.form[0].viewpointRecordMode === "thermal"
    },
    isPhotoModeSelected() {
      return this.form[0].viewpointRecordMode === 'photolapse'
    }
  },
  data() {
    return {
      save_form: [],
      form: [],
      form_array: [],
      lastValue: 3,
      lastForbiddenPanAngles: {
        min: 0,
        max: 0,
      },
      lastTilt: [-45, -10],
      forbiddenAnglesSliderMarks: {
        '-180': '-180°',
        '0': '0°',
        '180': '180°'
      },
      tiltSliderMarks: {
        '-90': '-90°',
        '0': '0°',
        '90': '90°'
      },
      slider: {
        value: 3,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        range: [
          { label: "1m" },
          { label: "2m", isHide: true },
          { label: "3m", isHide: true },
          { label: "4m" },
          { label: "5m", isHide: true },
          { label: "6m", isHide: true },
          { label: "7m" },
          { label: "8m", isHide: true },
          { label: "9m", isHide: true },
          { label: "10m" },
        ],
      },
      lastValues: {
        viewpointRecordMode: 'videohd',
        viewpointThermalMode: 'rainbow',
        viewpointThermalRange: 'low',
        viewpointPhotoModeInterval: 5
      },
      availRecordModes: VALID_RECORD_MODES.map(mode => {
        return {
            'label': this.$t(`cameraSettings.recordModes.${mode}`),
            'value': mode
          }
      }),
      availThermalModes: VALID_THERMAL_MODES.map(mode => {
        return {
            'label': this.$t(`cameraSettings.thermalModes.${mode}`),
            'value': mode
          }
      }),
      panForbiddenMaxChecked: false,
      panForbiddenMinChecked: false,
      tiltChecked: false,
      durationChecked: false,
      invalidFields: [],
      debounce: null,
    }
  },
  mounted () {
    this.save_form = [...this.view]
  },
  methods: {
    handlePropChange(propName, value) {
      this.form.forEach((view) => {view[propName] = value})
      this.lastValues[propName] = value
    },
    changeViewType(value) {
        this.form.forEach((view) => {
          if (value) {
            view.viewpointMode = 'auto'
          }
          else {
            view.viewpointMode = 'static'
          }
        })
    },
    changeRadius(value) {
      if (value !== this.lastValue) {
        this.form.forEach((pointForm) => {
            this.eventBus.$emit("change-radius-forbidden-ban-circle", pointForm, value);
        })
        this.lastValue = value;
      }
    },
    callbackRange(val) {
      this.rangeValue = val;
    },
    onForbiddenMaxPanChange(checked) {
      this.panForbiddenMaxChecked = checked;
      this.form.forEach((pointMax) => {
        var save = []
        this.save_form.forEach((save_point) => {
          if (pointMax.id == save_point.id)
            save.push(save_point)
        })
        if (!this.panForbiddenMaxChecked) {
          this.lastForbiddenPanAngles.max = pointMax.viewpointMaxForbiddenPan;
          pointMax.viewpointMaxForbiddenPan = save[0].viewpointMaxForbiddenPan;
      } else {
          pointMax.viewpointMaxForbiddenPan = this.lastForbiddenPanAngles.max;
      }
      this.eventBus.$emit("update-max-angle", pointMax);
      })
    },
    onForbiddenMinPanChange(checked){
      this.panForbiddenMinChecked = checked;
      this.form.forEach((pointMin) => {
        var save = []
        this.save_form.forEach((save_point) => {
          if (pointMin.id == save_point.id)
            save.push(save_point)
        })
        if (!this.panForbiddenMinChecked){
        this.lastForbiddenPanAngles.min = pointMin.viewpointMinForbiddenPan;
        pointMin.viewpointMinForbiddenPan = save[0].viewpointMinForbiddenPan;
      } else {
        pointMin.viewpointMinForbiddenPan = this.lastForbiddenPanAngles.min;
      }
      this.eventBus.$emit("update-min-angle", pointMin);
      })
    },
    onTiltChange(checked){
      this.tiltChecked = checked
      if (!this.tiltChecked) {
        this.form.forEach((pointForm) => {
        var save = []
        this.save_form.forEach((save_point) => {
          if (pointForm.id == save_point.id)
            save.push(save_point)
        })
        this.lastTilt = [pointForm.viewpointMinTilt, pointForm.viewpointMaxTilt]
        pointForm.viewpointMinTilt = save[0].viewpointMinTilt
        pointForm.viewpointMaxTilt = save[0].viewpointMaxTilt
      })
      } else {
        this.onTiltChangeValue(this.lastTilt)
      }
    },
    onDurationChange(checked){
      this.durationChecked = checked
      if (!this.durationChecked) {
        this.form.forEach((pointForm) => {
          var save = []
          this.save_form.forEach((save_point) => {
            if (pointForm.id == save_point.id)
              save.push(save_point)
          })
          pointForm.viewpointMoveTimeout = save[0].viewpointMoveTimeout
        })
      }
    },
    onPropChangeMax(value) {
      var val = Math.floor(value)
      if (val === "") {
        val = null;
      }
      if (typeof(val) == "string")
        if (val.slice(-1) == ".")
          val = null
      this.form.forEach((pointMax) => {
        if (isPanValid(val) && val !== pointMax["viewpointMaxForbiddenPan"] && !isNaN(val)) {
          pointMax["viewpointMaxForbiddenPan"] = val;
          this.eventBus.$emit("update-max-angle", pointMax);
        }
      })
    },
    onPropChangeMin(value){
      var val = Math.floor(value)
      if (val === "") {
        val = null;
      }
      if (typeof (val) == "string"){
        if (val.slice(-1) == "."){
          val = null
        }
      }
      this.form.forEach((pointMin)=>{
        if (isPanValid(val) && val !== pointMin["viewpointMinForbiddenPan"] && !isNaN(val)){
          pointMin["viewpointMinForbiddenPan"] = val;
          this.eventBus.$emit("update-min-angle", pointMin);
        }

      })
    },
    onTiltChangeValue(range) {
      this.form.forEach((pointForm) => {
        pointForm.viewpointMinTilt = range[0]
        pointForm.viewpointMaxTilt = range[1]
      })
    },
    onChangeDurationValue(value) {
      if (value === "") {
        value = null;
      }
      if (typeof(value) == "string")
        if (value.slice(-1) == ".")
          value = null

      this.form.forEach((pointForm) => {
        if (value !== pointForm["viewpointMoveTimeout"] && !isNaN(value)) {
          pointForm["viewpointMoveTimeout"] = Math.abs(value);
        }
      })
    },
    getValidateStatus(prop) {
      const idx = this.invalidFields.indexOf(prop);
      if (idx !== -1) {
        return "error"
      }
      return ""
    },
    saveProperties() {
      this.invalidFields = [];
      this.form.forEach((pointForm) => {
        var save = []
        this.save_form.forEach((save_point) => {
          if (pointForm.id == save_point.id)
            save.push(save_point)
        })
        if (
          pointForm.viewpointMaxForbiddenPan !== null &&
          !isPanValid(pointForm.viewpointMaxForbiddenPan)
        ) {
          this.invalidFields.push("viewpointMaxForbiddenPan")
        }
        if (
          pointForm.viewpointMinForbiddenPan !== null &&
          !isPanValid(pointForm.viewpointMinForbiddenPan)
        ) {
          this.invalidFields.push("viewpointMinForbiddenPan")
        }
        if (
          pointForm.viewpointMinTilt !== null &&
          !isTiltValid(pointForm.viewpointMinTilt)
        ) {
          this.invalidFields.push("viewpointMinTilt")
        }
        if (
          pointForm.viewpointMaxTilt !== null &&
          !isTiltValid(pointForm.viewpointMaxTilt)
        ) {
          this.invalidFields.push("viewpointMaxTilt")
        }
        if (
          pointForm.viewpointMoveTimeout !== null &&
          isNaN(Math.floor(Number(pointForm.viewpointMoveTimeout)))
        ) {
          this.invalidFields.push("viewpointMoveTimeout")
        }
        if (this.invalidFields.length === 0) {
          if (this.panForbiddenMaxChecked && !this.panForbiddenMinChecked) {
            pointForm.viewpointMinForbiddenPan = 0
          } else if (
            !this.panForbiddenMaxChecked &&
            this.panForbiddenMinChecked
          ) {
            pointForm.viewpointMaxForbiddenPan = 0
          }
          if (pointForm.isPausable) {
            this.eventBus.$emit("save-viewsegment-props", pointForm)
            this.eventBus.$emit("remove-pan-marker", pointForm)
            this.eventBus.$emit("remove-corridor", pointForm)
          }
          this.eventBus.$emit("save-viewpoint-props", pointForm)
          this.eventBus.$emit("remove-forbidden-pan", pointForm)
        }
      })
    },
    cancel() {
      this.eventBus.$emit("cancel-viewpoint-props")
      this.eventBus.$emit("cancel")
    },
  },
  watch: {
    view: {
      immediate: true,
      handler: function(pointFromProps) {
          pointFromProps.forEach((copy) => {
            this.form.push({...copy})
          })
          this.panForbiddenMaxChecked = (this.form[0].viewpointMaxForbiddenPan !== null);
          this.panForbiddenMinChecked = (this.form[0].viewpointMinForbiddenPan !== null);
          this.tiltChecked = (this.form[0].viewpointMinTilt !== null || pointFromProps[0].viewpointMaxTilt !== null);
          this.durationChecked = (this.form[0].viewpointMoveTimeout !== null);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.ant-row,
.ant-row-flex {
  margin-bottom: 5px;
}
.save-button {
  display: inline;
  margin-left: 15px;
}

.cancel-button {
  display: inline;
  margin: 8px 0 8px 0;
}
.ant-input-number {
  width: 100%;
  text-align: center;
}
.ant-input {
  width: 100%;
}
</style>
