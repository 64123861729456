<template>
  <div class="pointList-container" v-if="flightPlanId !== null && dataLoaded">
    <a-row style="marginBottom: 20px" type="flex">
      <a-col
        :md="{ span: 2, offset: 3 }"
        :lg="{ span: 2, offset: 2 }"
        :xl="{ span: 2, offset: (showMap ? 2 : 1) }"
        :xxl="{ span: 2, offset: 1 }"
      >
        <a-button @click="switchLayout">
          <a-icon type="border-horizontal" v-if="layout !== 'vertical'" />
          <a-icon type="border-verticle" v-else />
        </a-button>
      </a-col>
      <a-col
        :md="{ span: 3, offset: 14 }"
        :lg="{ span: 3, offset: (showMap ? 16 : 16) }"
        :xl="{ span: 3, offset: (showMap ? 16 : 16) }"
        :xxl="{ span: 3, offset: 18 }"
      >
        <a-button @click="showOrHideMap()">
          <a-icon :type="showMap ? 'right' : 'left'" />
          {{ showMap ? $t("common.hideMap") : $t("common.showMap") }}
        </a-button>

      </a-col>
    </a-row>
    <a-row style="marginBottom: 20px" type="flex" justify="start">
            <a-col
        v-show="batchPropsTarget !== null"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 22,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 18 : 18) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 19) : showMap ? 20 : 14,
          offset: layout === 'vertical' ? (showMap ? 4 : 3) : showMap ? 2 : 0,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 18) : 14,
          offset: layout === 'vertical' ? (showMap ? 1 : 3) : 0,
        }"
        :order="layout === 'vertical' ? 1 : showMap ? 3 : 2"
      >
        <batch-props-form
          v-if="batchPropsTarget !== null"
          :point="editingBatchTarget"
          :eventBus="eventBus"
          @cancel="() => cancelBatchProps()"
        ></batch-props-form>
      </a-col>

      <a-col
        v-show="viewpointsDataPropsTarget.length !== 0"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 22,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 18 : 18) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 19) : showMap ? 20 : 14,
          offset: layout === 'vertical' ? (showMap ? 4 : 3) : showMap ? 2 : 0,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 18) : 14,
          offset: layout === 'vertical' ? (showMap ? 1 : 3) : 0,
        }" :order="layout === 'vertical' ? 1 : showMap ? 3 : 2">
        <a-row>
          <a-col :span="4" :offset="2">
            <a-button @click="backHomeFromViewProps()">
              <a-icon type="left" /> {{ $t("common.goBack") }}
            </a-button>
          </a-col>
        </a-row>
        <a-col style="margin-top: 20px;" :span="20" :offset="2">
        <view-props-form v-if="viewpointsDataPropsTarget.length !== 0" :view="viewpointsDataPropsTarget"
          :eventBus="eventBus" @cancel="() => cancelViewpointProps()"></view-props-form>
      </a-col>
      </a-col>

      <a-col
        v-show="switchpointsDataPropsTarget.length !== 0"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 22,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 18 : 18) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 19) : showMap ? 20 : 14,
          offset: layout === 'vertical' ? (showMap ? 4 : 3) : showMap ? 2 : 0,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 18) : 14,
          offset: layout === 'vertical' ? (showMap ? 1 : 3) : 0,
        }"
        :order="layout === 'vertical' ? 1 : showMap ? 3 : 2"
      >
        <switchpoint-props-form
          v-if="switchpointsDataPropsTarget.length !== 0"
          :points="switchpointsDataPropsTarget"
          :eventBus="eventBus"
          @cancel="() => cancelViewpointProps()"
        ></switchpoint-props-form>
      </a-col>

      <a-col
        v-show="(
          batchPropsTarget === null &&
          viewpointsDataPropsTarget.length == 0 &&
          switchpointsDataPropsTarget.length == 0
        )"
        :md="{
          span: layout === 'vertical' ? (showMap ? 20 : 19) : 20,
          offset: layout === 'vertical' ? (showMap ? 3 : 3) : 3,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 20 : 20) : 20,
          offset: layout === 'vertical' ? (showMap ? 2 : 2) : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 21 : 21) : showMap ? 20 : 19,
          offset: layout === 'vertical' ? (showMap ? 2 : 1) : showMap ? 2 : 3,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 11 : 21) : 21,
          offset: layout === 'vertical' ? (showMap ? 1 : 1) : 1,
        }"
        :order="layout === 'vertical' ? 1 : showMap ? 3 : 2"
      >
        <a-row style="marginBottom: 20px;" type="flex">
          <div class="gadgets-table">
            <a-col 
              v-if="isFPManager"
              :md="6"
              :lg="6"
              :xl="8"
              :xxl="8"
              align="left"
            >
              <a-button
                :disabled="disableAddButton"
                class="btn btn-primary"
                type="primary"
                @click="addRow()"
                >{{ $t("form.add") }}</a-button
              >
              &#09;
              <a-dropdown
              :disabled="disableEditButton"
              :trigger="['click']">
                <a-button>
                  <a-icon style="color: #124c5a" type="more"/>
                </a-button>
                <a-menu slot="overlay">
                 <a-sub-menu key="sub">
                    <span slot="title"><a-icon type="edit" />{{$t("form.edit")}}</span>
                    <a-menu-item v-if="isFPManager" @click="edit_batch()">
                      <a-icon class="btn-icon" type="unordered-list" />{{
                              $t("form.all")
                            }}
                    </a-menu-item>
                    <a-menu-item v-if="isFPManager" @click="properties_batch()">
                      <a-icon class="btn-icon" type="plus" />Viewpoint {{
                              $t("points.properties")
                            }}
                    </a-menu-item>
                  </a-sub-menu>
                  <a-menu-item v-if="isFPManager" @click="delete_batch()">
                    <a-icon class="btn-icon" type="delete" />{{$t("form.delete")}}
                  </a-menu-item>
                </a-menu>
              </a-dropdown>

            </a-col>
            <a-col
              :td="{ span: 8, offset: isFPManager ? 10 : 0 }"
              :md="{ span: 8, offset: isFPManager ? 10 : 0 }"
              :lg="{ span: 8, offset: isFPManager ? 10 : 0 }"
              :xl="{ span: 8, offset: isFPManager ? 8 : 0 }"
              :xxl="{ span: 8, offset: isFPManager ? 8 : 0 }"
              style=" display: flex;"
            >
                      <a-select
              v-model="formData.selectKey"
              mode="multiple"
              :placeholder="$t('points.select')"
              style="width: 150%"
              @change="showMarkersByType"
            >
              <a-select-option value="basepoint">Basepoint</a-select-option>
              <a-select-option value="waypoint">Waypoint</a-select-option>
              <a-select-option value="viewpoint">Viewpoint</a-select-option>
              <a-select-option value="safepoint">Safepoint</a-select-option>
              <a-select-option value="fallbackpoint"
                >Fallbackpoint</a-select-option
              >
            </a-select>
              <a-input-search style='padding-left: 20px'
                @change="searchQueryChanged()"
                class="input-search-table"
                :placeholder="$t('common.search')"
                v-model="searchQuery"
              />
            </a-col>
          </div>
        </a-row>
        <a-row type="flex">
          <a-table
            :row-selection="{ filteredPoints: filteredPoints, onChange: onSelectChange }"
            :customRow="customRow"
            :columns="columns"
            :dataSource="filteredPoints"
            rowKey="id"
            :pagination="currentPage"
            @change="onTableChanged"
          >
            <template slot="pointName" slot-scope="text, record, index">
              <a-form-item
                v-if="editingTarget && record.id === editingTarget.id"
                :validate-status="
                  invalidKeys.indexOf('pointName') === -1 ? '' : 'error'
                "
              >
                <a-input
                  style="margin: -5px 0"
                  :value="editingTarget.pointName"
                  @change="
                    (e) => handleChange(e.target.value, index, 'pointName')
                  "
                  @keyup.enter="() => save(editingTarget)"
                  ref="pointName"
                />
              </a-form-item>
              <template v-else >{{ text }}</template>
            </template>

            <template
              v-for="col in ['latitude', 'longitude', 'altitude']"
              :slot="col"
              slot-scope="text, record, index"
            >
              <div :key="col" >
                <a-form-item
                  v-if="editingTarget && record.id === editingTarget.id"
                  :validate-status="
                    invalidKeys.indexOf(col) === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    style="margin: -5px 0"
                    :value="editingTarget[col]"
                    @change="(e) => handleChange(e.target.value, index, col)"
                    v-on:keyup.enter="() => save(editingTarget)"
                    :ref="col"
                  />
                </a-form-item>
                <template v-else>{{ text }}</template>
              </div>
            </template>

            <template slot="pointType" slot-scope="text, record, index">
              <a-form-item 
                v-if="editingTarget && record.id === editingTarget.id"
              >
                <a-select
                  show-search
                  style="width: 100%"
                  option-filter-prop="types"
                  :filter-option="filterTypes"
                  :value="editingTarget.pointType"
                  @change="(val) => handleChange(val, index, 'pointType')"
                >
                  <a-select-option
                    :disabled="basePointExists() || PointsInSegments()"
                    value="basepoint"
                  >
                    Basepoint
                  </a-select-option>
                  <a-select-option value="waypoint">Waypoint</a-select-option>
                  <a-select-option value="viewpoint">Viewpoint</a-select-option>
                  <a-select-option value="switchpoint">Switchpoint</a-select-option>
                  <a-select-option 
                  :disabled="PointsInSegments()"
                    value="safepoint">Safepoint</a-select-option>
                  <a-select-option
                    :disabled="fallbackPointExists() || PointsInSegments()"
                    value="fallbackpoint"
                  >
                    Fallbackpoint
                  </a-select-option>
                </a-select>
              </a-form-item>
              <template v-else>{{
                text.charAt(0).toUpperCase() + text.slice(1)
              }}</template>
            </template>

            <template slot="action" slot-scope="text, record">
              <div class="action-container">
                <span v-if="editingTarget && record.id === editingTarget.id">
                  <a-row class="btn-form">
                    <a-col  :span="showMap ? 13 : 12" :xxl="12">
                      <a-button
                        class="btn-editing"
                        @click="() => save(editingTarget)"
                        type="link"
                        :title="$t('form.save')"
                      >
                        <a-icon class="btn-icon-table editing-form" type="check" />
                      </a-button>
                    </a-col>
                    <a-col :span="12">
                      <a-button
                        class="btn-editing btn-cancel"
                        @click="() => cancel()"
                        type="link"
                        :title="$t('form.cancel')"
                      >
                        <a-icon class="btn-icon-table editing-form" type="close" />
                      </a-button>
                    </a-col>
                  </a-row>
                </span>
                <span v-else-if="editingTarget === null">
                  <a-row
                    v-show="
                      isFPManager ||
                        (!isFPManager && displayViewpointPropsLink(record))
                    "
                  >
                    <div class="edition-button-table">
                      <a-dropdown :trigger="['click']">
                        <a-button
                          ><a-icon style="color: #124c5a" type="more"
                        /></a-button>
                        <a-menu slot="overlay">
                          <a-menu-item v-if="isFPManager" @click="edit(record)"
                          >
                            <a-icon class="btn-icon" type="edit" />{{
                              $t("form.edit")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-if="isFPManager"
                            @click="duplicate(record)"
                          >
                            <a-icon class="btn-icon" type="copy" />{{
                              $t("form.duplicate")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-if="isFPManager"
                            @click="confirmDelete(record)"
                          >
                            <a-icon class="btn-icon" type="delete" />{{
                              $t("form.delete")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-show="displayViewpointPropsLink(record)"
                            @click="showProperties(record, viewpointsDataPropsTarget)"
                          >
                            <a-icon class="btn-icon" type="plus" />{{
                              $t("points.properties")
                            }}
                          </a-menu-item>
                          <a-menu-item
                            v-show="displaySwitchpointPropsLink(record)"
                            @click="showProperties(record, switchpointsDataPropsTarget)"
                          >
                            <a-icon class="btn-icon" type="plus" />{{
                              $t("points.properties")
                            }}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown>
                    </div>
                  </a-row>
                </span>
              </div>
            </template>
          </a-table>
        </a-row>
      </a-col>

      <a-col
        :md="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 16 : 3,
          offset: layout === 'vertical' ? (showMap ? 4 : 1) : showMap ? 3 : 2,
        }"
        :lg="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 17 : 3,
          offset: layout === 'vertical' ? (showMap ? 4 : 1) : showMap ? 2 : 2,
        }"
        :xl="{
          span: layout === 'vertical' ? (showMap ? 16 : 3) : showMap ? 16 : 3,
          offset: layout === 'vertical' ? (showMap ? 3 : 1) : showMap ? 2 : 2,
        }"
        :xxl="{
          span: layout === 'vertical' ? (showMap ? 8 : 3) : showMap ? 17 : 3,
          offset: layout === 'vertical' ? (showMap ? 1 : 1) : showMap ? 1 : 2,
        }"
        :order="layout !== 'vertical' ? 1 : 2"
        class="map-container"
      >
        <a-row style="marginBottom: 8px;">
          <a-col
            :md="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 5 : 0,
            }"
            :lg="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            :xl="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            :xxl="{
              span: layout === 'vertical' ? 12 : 12,
              offset: layout === 'vertical' ? 0 : 0,
            }"
            v-show="showMap"
          >
          </a-col>
        </a-row>
        <a-row v-show="showMap">
          <!-- Map -->
          <a-button @click="switchMap('map')" :disabled="type_map === 'map'">
            <a-icon type="global"/>
          </a-button>
          &#09;
          <a-button  @click="switchMap('graph-up') " :disabled="type_map === 'graph-up'">
            <a-icon type="caret-up" />
          </a-button>
          &#09;
          <a-button  @click="switchMap('graph-left')" :disabled="type_map === 'graph-left'">
            <a-icon type="caret-left" />
          </a-button>
          <point-chart v-if="type_map !== 'map'"
            :eventBus="eventBus"
            :points="points"
            :longi_latis=longi_lati
          />
          <points-map v-if="type_map === 'map'"
            :eventBus="eventBus"
            :points="points"
            :geofenceStatus="geofenceStatus"
            :editingTarget="editingTarget"
            :status_edit="status_edit"
          >
            <template v-if="isFPManager" v-slot:map-footer>
              <geofence-buttons
                :eventBus="eventBus"
                :geofenceStatus="geofenceStatus"
              />
            </template>
          </points-map>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Table,
  Input,
  Select,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Menu,
  Icon,
  Modal
} from "ant-design-vue";
import ViewPropsForm from "../ViewPropsForm.vue";
import SwitchpointPropsForm from "./SwitchpointPropsForm";
import { flightplanTypes } from "../../../store/mutation-types";
import PointsMap from "./PointsMap";
import GeofenceButtons from "./GeofenceButtons";
import { isGpsCoordinateValid, isHeightValid } from "../FormValidation"
import { isFPManager } from "../../../utils/userAuth";
import PointChart from "./PointChart";
import BatchPropsForm from './BatchPropsForm.vue';

Vue.use(Table);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Icon);
Vue.use(Modal);

let flightPlansUnwatcher = null;
let currentFlightPlanIdUnwatcher = null;

export default {
  name: "pointList",
  components: {
    ViewPropsForm,
    SwitchpointPropsForm,
    PointsMap,
    GeofenceButtons,
    PointChart,
    BatchPropsForm
  },
  computed: {
    isFPManager() {
      return isFPManager();
    },
    columns() {
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "pointName",
          key: "pointName",
          width: "20%",
          scopedSlots: { customRender: "pointName" },
          sorter: (a, b) => a.pointName.localeCompare(b.pointName),
          sortOrder: sortedInfo.columnKey === "pointName" && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: this.$t("points.type"),
          dataIndex: "pointType",
          key: "pointType",
          width: "20%",
          scopedSlots: { customRender: "pointType" },
          sorter: (a, b) => a.pointType.localeCompare(b.pointType),
          sortOrder: sortedInfo.columnKey === "pointType" && sortedInfo.order,
          ellipsis: true,
        },
        {
          title: this.$t("points.latitude"),
          dataIndex: "latitude",
          key: "latitude",
          width: "16%",
          scopedSlots: { customRender: "latitude" },
          sorter: (a, b) => a.latitude - b.latitude,
          sortOrder: sortedInfo.columnKey === "latitude" && sortedInfo.order,
        },
        {
          title: this.$t("points.longitude"),
          dataIndex: "longitude",
          key: "longitude",
          width: "18%",
          scopedSlots: { customRender: "longitude" },
          sorter: (a, b) => a.longitude - b.longitude,
          sortOrder: sortedInfo.columnKey === "longitude" && sortedInfo.order,
        },
        {
          title: this.$t("points.height"),
          dataIndex: "altitude",
          key: "altitude",
          width: "16%",
          scopedSlots: { customRender: "altitude" },
          sorter: (a, b) => a.altitude - b.altitude,
          sortOrder: sortedInfo.columnKey === "altitude" && sortedInfo.order,
        },
        {
          dataIndex: "action",
          width: "13%",
          scopedSlots: { customRender: "action" },
        },
      ];
      return columns;
    },
    disableEditButton() {
      return this.selectedRowKeys.length == 0;
    },
    disableAddButton() {
      return this.editingTarget !== null;
    },
    flightPlanId() {
      return this.$store.state.flightPlans.currentFlightPlanId;
    },
    filteredPoints() {
      var points = []
      if (this.formData.selectKey === undefined)
        points = this.points
      else if (this.formData.selectKey) {
        if (this.formData.selectKey.length === 0) {
          points = this.points
        }
        else {
         this.points.filter((item) => {
          if (this.formData.selectKey.includes(item.pointType)) {
          points.push(item)
          }
        });
        }
      }
      if (this.coordinatesFilter.latitude !== null &&
          this.coordinatesFilter.longitude !== null) {
        return points.filter((item) =>
            item.latitude === this.coordinatesFilter.latitude &&
            item.longitude === this.coordinatesFilter.longitude
        )
      }
      else if (this.searchQuery) {
        return points.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.pointName.toLowerCase().includes(v));
        });
      } else {
        return points;
      }
    },
  },
  created() {
    flightPlansUnwatcher = this.$store.subscribe((mutation) => {
      if (mutation.type === flightplanTypes.FETCH_FLIGHTPLANS) {
        this.layout = this.$store.getters.getUserPreferences().layout
        this.refreshPointsFromStore();
        this.dataLoaded = true;
      }
    });

    currentFlightPlanIdUnwatcher = this.$store.watch(
      (state) => state.flightPlans.currentFlightPlanId,
      () => {
        this.refreshPointsFromStore();
        this.dataLoaded = true;
        this.eventBus.$emit("flightplan-changed");
      }
    );
    this.eventBus.$on("set-geofence-status", this.onSetGeofenceStatus);
  },
  mounted() {
    this.eventBus.$on("map-clicked", this.onMapClicked);

    this.eventBus.$on("filter-coordinates", this.onFilterCoordinates);

    this.eventBus.$on("save-position", this.save);
    this.eventBus.$on("duplicate-point", this.duplicate);
    this.eventBus.$on("save-viewpoint-props", this.saveViewpointProps);
    this.eventBus.$on("save-switchpoints-props", this.saveSwitchpointProps);
    this.eventBus.$on("save-batch-props", this.saveBatchpointProps);
    this.eventBus.$on("cancel-viewpoint-props", this.cancelViewpointProps);
    this.eventBus.$on("cancel-switchpoint-props", this.cancelSwitchpointProps);
    this.eventBus.$on("cancel-batch-props", this.cancelBatchProps);

    if (this.flightPlanId) {
      if (this.$store.state.flightPlans.lastFetch === null) {
        this.$store.dispatch("fetchFlightPlans");
      } else {
        this.refreshPointsFromStore();
        this.dataLoaded = true;
      }
    }

    if (this.$store.state.preferences.lastFetch === null) {
      this.$store.dispatch("fetchUserPreferences");
    }
    this.showMap = this.$store.getters.getUserPreferences().showMap;
    this.layout = this.$store.getters.getUserPreferences().layout;
  },
  beforeDestroy() {
    if (currentFlightPlanIdUnwatcher) {
      currentFlightPlanIdUnwatcher();
    }

    if (flightPlansUnwatcher) {
      flightPlansUnwatcher();
    }
  },
  data() {
    return {
      eventBus: new Vue(),
      dataLoaded: false,
      editingTarget: null,
      editingBatchTarget: [],
      selectedRowKeys: [],
      points: [],
      save_points: [],
      longi_lati: 'longitude',
      geofenceStatus: "stopped",
      viewpointsDataPropsTarget: [],
      switchpointsDataPropsTarget: [],
      batchPropsTarget: null,
      invalidKeys: [],
      showMap: null,
      currentPage: {
        defaultCurrent: 1,
        current: 1,
      },
      searchQuery: null,
      formData: {
        selectKey: undefined
      },
      coordinatesFilter: {
        latitude: null,
        longitude: null
      },
      filteredInfo: null,
      sortedInfo: null,
      layout: null,
      points_used: 0,
      type_map: "map",
      status_edit: null,
    };
  },
  methods: {
    searchQueryChanged() {
      var ids_query = []
      var stock_query = []
      this.points.forEach((id) => {
        var pointNames = id.pointName.toLowerCase()
        if (pointNames.includes(this.searchQuery.toLowerCase())) {
          ids_query.push(id.id)
          stock_query.push(id)
        }
      })
      this.eventBus.$emit("show-shearched-points", ids_query);
      this.eventBus.$emit("search-selected-points-chart", ids_query);
    },
    switchMap(type_map) {
      this.formData.selectKey = undefined;
      if (type_map == 'map') {
        this.type_map = "map"
      }
      if (type_map === "graph-up") {
        this.longi_lati = "longitude"
        this.type_map = "graph-up"
      }
      if (type_map === "graph-left") {
        this.longi_lati = "latitude"
        this.type_map = "graph-left"
      }
    },
    check_points_used(record) {
      if (this.points_used !== null) {
        var index;
        for (index = 0; index < this.points_used.length; index++) {
          if (this.points_used[index] == record.id)
          return 1
        }

      }
    },
    customRow(record, index) {
      return {
        style: {
          'background-color': this.check_points_used(record) === 1 ? "#fff1f0" : 'white',
        },
      }
    },
    switchLayout() {
      this.layout = this.layout === "vertical" ? "horizontal" : "vertical";
      this.$store.dispatch("setLayoutPreference", {
        preferences: {...this.$store.getters.getUserPreferences()},
              user: {...this.$store.getters.getCurrentUser()},
                            layout: this.layout,
      });
    },
    filterTypes(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    showMarkersByType(value) {
      this.eventBus.$emit("show-selected-points", value);
    },
    showOrHideMap() {
      this.showMap = this.showMap ? false : true;
    },
    onFilterCoordinates(coordinates) {
      if (!this.editingTarget &&
          coordinates.latitude !== null &&
          coordinates.longitude !== null) {
        this.coordinatesFilter = coordinates;
      }
    },
    onTableChanged(pagination, filters, sorter) {
      this.currentPage.current = pagination.current;
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
    },
    onMapClicked(latLng) {
      if (this.editingTarget !== null) {
        this.editingTarget = {
          ...this.editingTarget,
          latitude: Number.parseFloat(latLng.latitude.toFixed(6)),
          longitude: Number.parseFloat(latLng.longitude.toFixed(6)),
        };
      } else {
        this.resetCoordinatesFilter();
      }
    },
    onSetGeofenceStatus(status) {
      this.geofenceStatus = status;
    },
    PointsInSegments() {
      var check_from = this.$store.getters.getFlightPlanById(this.flightPlanId).segments.some((p) => p.firstPointId === this.editingTarget.id);
      var check_to = this.$store.getters.getFlightPlanById(this.flightPlanId).segments.some((p) => p.secondPointId === this.editingTarget.id);
      if (check_from || check_to)
        return true
    },
    basePointExists() {
      return this.points.some((p) => p.pointType === "basepoint");
    },
    fallbackPointExists() {
      return this.points.some((point) => point.pointType === "fallbackpoint");
    },
    refreshPointsFromStore() {
      if (this.flightPlanId !== null) {
        this.points = JSON.parse(
          JSON.stringify(
            this.$store.getters.getFlightPlanById(this.flightPlanId).points
          )
        );
          this.selectedRowKeys.splice(0, this.selectedRowKeys.length)
      }
    },
    handleChange(value, key, column) {
      if (this.editingTarget) {
        this.editingTarget[column] = value;
        this.eventBus.$emit("visualize-change", this.editingTarget);
      }
    },
    edit(target) {
      this.editingTarget = { ...target };
    },
    duplicate(target) {
      if (target.pointType !== "basepoint") {
        const newPoint = {
          ...target,
          id: "new",
          pointName: "",
          latitude: target.latitude,
          longitude: target.longitude,
        };
        this.currentPage.current = 1;
        this.points.unshift(newPoint);
        this.editingTarget = newPoint;
        this.$nextTick(() => {
          if (this.$refs["pointName"]) {
            this.$refs["pointName"][0].focus();
          }
        });
      } else {
        this.$store
          .dispatch("setLastHTTPError", {
            status: 0,
            message: "Base point already exists.",
          })
          .then(() => {});
      }
    },
    confirmDelete(target) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store
            .dispatch("deletePoint", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              point: target,
            })
            .then(() => this.refreshPointsFromStore());
        },
        onCancel: () => {},
      });
    },
    validatePoint(point) {
      let invalidKeys = [];
      if (!isGpsCoordinateValid(point.latitude)) {
        invalidKeys.push("latitude");
      }
      if (!isGpsCoordinateValid(point.longitude)) {
        invalidKeys.push("longitude");
      }
      if (!isHeightValid(point.altitude)) {
        invalidKeys.push("altitude");
      }
      if (point.pointName === "") {
        invalidKeys.push("pointName");
      }
      this.invalidKeys = invalidKeys;
      return invalidKeys.length === 0;
    },

    generatePointName(point) {
      if (point !== null && point.pointName == "" && point.latitude !== null && point.longitude !== null) {
        const type = point.pointType

        if (point.pointType == "basepoint" || point.pointType == "fallbackpoint")
          point.pointName = type.replace("point", "").charAt(0).toUpperCase() + type.replace("point", "").slice(1);
        else {
          const points = this.points.filter(p => p.pointType === type)
          let prefix = type.charAt(0).toUpperCase() + type.replace("point", "").slice(1) + 'P_'
          let count = 1
          let found = true

          while ((count < points.length + 1) && found) {
            let elem = points.find(p => p.pointName === `${prefix}${count}`)

            if (!elem) {
              found = false
            } else {
              count++
            }
          }

          point.pointName = `${prefix}${count}`
        }
      }
    },
    save(point) {
      if (Object.hasOwnProperty.call(point, "id")) {
        this.status_edit = "save";
        this.generatePointName(point)
        this.$store.commit('updateAltitude', point.altitude)
        point.latitude = Number.parseFloat(point.latitude);
        point.longitude = Number.parseFloat(point.longitude);

        if (this.validatePoint(point)) {
          let isNew = point.id === "new";
          let promise;
          if (isNew) {
            if (point.pointType === "viewpoint") {
              point.viewpointMode = "auto";
              point.viewpointMinTilt = null;
              point.viewpointMaxTilt = null;
              point.viewpointMinForbiddenPan = null;
              point.viewpointMaxForbiddenPan = null;
              point.viewpointMoveTimeout = null;
              point.viewpointRecordMode = null;
              point.viewpointPhotoModeInterval = null;
              point.viewpointThermalMode = null;
              point.viewpointThermalRange = null;
            }
            if (point.pointType == "basepoint")
              this.eventBus.$emit("center-map-on-point", point);
            promise = this.$store.dispatch("addPoint", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              point,
            });
          } else {
            if (point.pointType == "basepoint")
              this.eventBus.$emit("center-map-on-point", point);
            promise = this.$store.dispatch("updatePoint", {
              flightPlan: this.$store.getters.getFlightPlanById(
                this.flightPlanId
              ),
              point,
            });
          }
          promise.then(() => {
            this.editingTarget = null;
            this.viewpointsDataPropsTarget.length = 0
            this.refreshPointsFromStore();
          });
        }
      }
    },
    cancel() {
      this.status_edit = "cancel";
      this.editingTarget = null;
      this.invalidKeys = [];
      var index = this.points.findIndex((p) => p.id === "new");
      if (index > -1) {
        this.points.splice(index, 1);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    properties_batch() {
      this.selectedRowKeys.forEach((point) => {
        var stock_point = this.$store.getters.getCurrentPointById(this.$store.getters.getFlightPlanById(this.flightPlanId),point)
        if(stock_point.pointType == "viewpoint") {
          this.viewpointsDataPropsTarget.push(stock_point)
        }
      })
      if (this.viewpointsDataPropsTarget.length > 0)
        this.showProperties(this.viewpointsDataPropsTarget)
    },
    edit_batch() {
      this.selectedRowKeys.forEach((point) => {
        this.editingBatchTarget.push(this.$store.getters.getCurrentPointById(this.$store.getters.getFlightPlanById(this.flightPlanId),point))
      })
      this.batchPropsTarget = "open"
    },
    delete_batch() {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store.dispatch("deleteMultiplePoints", {
            flightPlan: this.$store.getters.getFlightPlanById(
              this.flightPlanId
            ),
            points: this.selectedRowKeys,
          })
          .then(() => this.refreshPointsFromStore()) 
          .catch(() => {
            this.points_used = this.$store.getters.getErrorPoints
            this.refreshPointsFromStore()
          });
        },
        onCancel: () => {},
      });
    },
    addRow() {
      this.currentPage.current = 1;
      this.resetSorter();
      this.resetCoordinatesFilter();

      const newPoint = {
        id: "new",
        pointName: "",
        pointType: this.basePointExists() ? "waypoint" : "basepoint",
        latitude: null,
        longitude: null,
        altitude: this.$store.getters.getAltitude,
      };
      this.points.unshift(newPoint);
      this.editingTarget = newPoint;
      this.$nextTick(() => {
        if (this.$refs["pointName"]) {
          this.$refs["pointName"].focus();
        }
      });
    },
    resetSorter() {
      this.filteredInfo = null;
      this.sortedInfo = null;
    },
    resetCoordinatesFilter() {
      this.coordinatesFilter = {
        latitude: null,
        longitude: null
      };
    },
    displayViewpointPropsLink(point) {
      if (
        this.editingTarget !== null &&
        point.id === this.editingTarget.id &&
        this.editingTarget.pointType === "viewpoint"
      ) {
        return true;
      } else if (
        this.editingTarget === null &&
        point.pointType === "viewpoint"
      ) {
        return true;
      }
      return false;
    },
    displaySwitchpointPropsLink(point) {
      if (
        this.editingTarget !== null &&
        point.id === this.editingTarget.id &&
        this.editingTarget.pointType === "switchpoint"
      ) {
        return true;
      } else if (
        this.editingTarget === null &&
        point.pointType === "switchpoint"
      ) {
        return true;
      }
      return false;
    },
    showProperties(target, dataPropsTarget) {
      let fctShow = (t) => {
        if (t.pointType === "viewpoint") {
          var copy_target = {...t};
          this.eventBus.$emit(
            "draw-viewpoint-forbidden-pan",
            copy_target
          );
        }
      };
      var array = []
      if (Array.isArray(target) == false) {
            array.push(target)
            dataPropsTarget.push(target)
      }
      else {
        array = target
      }
      array.forEach((targets) => {
        if (this.editingTarget && targets.id === this.editingTarget.id) {
          fctShow(this.editingTarget);
        } else {
          fctShow(targets);
        }
      })
      this.eventBus.$emit("center-map-on-point", array[0]);
    },
    saveBatchpointProps(props) {
      props.forEach((id) => {
        this.save(id)
      })
      this.batchPropsTarget = null;
      this.editingBatchTarget = []
      this.refreshPointsFromStore();
    },
    saveViewpointProps(props) {
      if (this.editingTarget === null) {
        const pIdx = this.points.findIndex(
          (p) => p.id === props.id
        );
        if (pIdx !== -1) {
          this.points[pIdx] = props;
          this.save(props);
        }
      } else {
        this.editingTarget = { ...this.props };
      }
      this.viewpointsDataPropsTarget = []
      this.refreshPointsFromStore();
    },
    saveSwitchpointProps(newPoints) {
      newPoints.forEach(np => {
        const npIdx = this.points.findIndex(p => p.id === np.id)

        if (npIdx !== -1) {
          this.points[npIdx] = np
          this.save(np)
        }
      });
      this.switchpointsDataPropsTarget = []
      this.refreshPointsFromStore();
    },
    cancelViewpointProps() {
      this.viewpointsDataPropsTarget.forEach((id) => {
        this.eventBus.$emit("remove-forbidden-pan", id);
      })
      this.editingTarget = null;
      this.viewpointsDataPropsTarget = []
      this.refreshPointsFromStore();
    },
    cancelSwitchpointProps() {
      this.editingTarget = null;
      this.switchpointsDataPropsTarget = []
      this.refreshPointsFromStore();
    },
    cancelBatchProps() {
      this.editingBatchTarget = []
      this.batchPropsTarget = null;
      this.refreshPointsFromStore();
    },
    backHomeFromViewProps(){
      this.eventBus.$emit("cancel-viewpoint-props")
    }
  },
};
</script>

<style lang="less" scoped>
.action-container {
  padding-left: 0;
  }

.btn-form {
  height: 2.5rem;
  width: 3.75rem;
}
.btn-icon-table {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
}

.btn-editing {
  align-items: center;
}

.btn-cancel {
  color: red;
}
.editing-form {
    font-size: 1.5rem;
}

.gadgets-table {
  width: 100%;
  .input-search-table {
    width: 100%;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

@media screen and (min-device-width: 768px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.3rem;
  }
}

@media screen and (min-device-width: 992px) {
  .btn-form {
    height: 3.25rem;
  }

  .btn-editing {
    margin-bottom: 1.25rem;
  }
}

@media screen and (min-device-width: 1200px) {
  .btn-form {
    height: 3.25rem;
  }
}

@media screen and (min-device-width: 1600px) {
  .container {
    padding-left: 20px;
    margin-right: -20px;
  }

  .btn-form {
    height: 2.5rem;
    width: 100%;
  }

  .btn-editing {
    margin-bottom: 1.35rem;
  }
}

.ant-table {
  display: block;
  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }
    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }
        &:last-of-type {
          padding-bottom: 1rem;
        }
      }
      > th,
      > td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;
        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}
</style>
